/* eslint-disable @typescript-eslint/camelcase */
import {
  ConvertObjectCamelToSnakeCase,
  toFormDataUtils
} from "@/app/infrastructures/misc/Utils";
import { QueryParamsEntities } from "@/domain/entities/MainApp";
import {
  BulkUploadRequestInterface,
  BulkUploadRetailPriceRequestInterface,
  EditClientTemplateRequestInterface,
  CreateClientTemplateRequestInterface
} from "../contracts/BulkRequest";

export class BulkUploadCsvApiRequest implements BulkUploadRequestInterface {
  private archive_type: string;
  private activity_name: string;
  private archive_file: string;
  private start_date: string;
  private end_date: string;
  private is_scheduler: boolean;
  private custom_status: string;
  private commodity_surcharge_status: string;

  constructor(
    archiveType: string,
    activityName: string,
    archiveFile: any,
    start_date: string,
    end_date: string,
    is_scheduler: boolean,
    customStatus: string,
    commodity_surcharge_status: string
  ) {
    this.archive_type = archiveType;
    this.activity_name = activityName;
    this.archive_file = archiveFile;
    this.start_date = start_date;
    this.end_date = end_date;
    this.is_scheduler = is_scheduler;
    this.custom_status = customStatus;
    this.commodity_surcharge_status = commodity_surcharge_status;
  }

  public toFormData() {
    const data = {
      archive_type: this.archive_type,
      activity_name: this.activity_name,
      archive_file: this.archive_file,
      start_date: this.start_date,
      end_date: this.end_date,
      is_scheduler: this.is_scheduler,
      custom_status: this.custom_status,
      commodity_surcharge_status: this.commodity_surcharge_status
    };
    return toFormDataUtils(data);
  }
}
export class BulkUploadXlsxApiRequest
  implements BulkUploadRetailPriceRequestInterface {
  archiveType = "";
  activityName = "";
  archiveFile: any = "";
  versionName = "";
  startDate = "";
  endDate = "";
  clientId = 0;
  isScheduler = false;
  rateActiveDate = "";
  archivePromoStatus = "";
  constructor(fields?: Partial<BulkUploadXlsxApiRequest>) {
    Object.assign(this, fields);
  }

  public toFormData() {
    return toFormDataUtils(ConvertObjectCamelToSnakeCase(this));
  }
}

export class RequestBulkBooking {
  archiveType = "";
  page = 1;
  limit = 20;
  search = "";
  status = "";
  createdType = "";
  createdId = 0;
  version = 2;
  isTotalData = false;
  constructor(fields?: Partial<RequestBulkBooking>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class EditClientTemplateConfiguration
  implements EditClientTemplateRequestInterface {
  headerMapping: Array<any>;

  constructor(headerMapping: Array<any>) {
    this.headerMapping = headerMapping;
  }

  public toJSON() {
    const header_mapping = this.headerMapping.map((item: any) => {
      return {
        genesis_header: item.genesisHeader,
        source_header: item.sourceHeader
      };
    });

    return JSON.stringify({
      header_mapping
    });
  }
}

export class CreateClientTemplateConfiguration
  implements CreateClientTemplateRequestInterface {
  accountRefId: number;
  accountRefType: string;
  headerMapping: Array<any>;

  constructor(
    accountRefId: number,
    accountRefType: string,
    headerMapping: Array<any>
  ) {
    (this.accountRefId = accountRefId),
      (this.accountRefType = accountRefType),
      (this.headerMapping = headerMapping);
  }

  public toJSON() {
    const account_ref_id = this.accountRefId;
    const account_ref_type = this.accountRefType;
    const header_mapping = this.headerMapping.map((item: any) => {
      return {
        genesis_header: item.genesisHeader,
        source_header: item.sourceHeader
      };
    });

    return JSON.stringify({
      account_ref_id,
      account_ref_type,
      header_mapping
    });
  }
}
